<template>
  <div>
    <b-card no-body>
      <b-card-body>
        <b-row>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              :label="$t('labels.name')"
              label-for="name"
            >
              <b-form-input
                id="title"
                :value="searchFields.name_value"
                @input="inputChange($event, 'name_value')"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <label>{{ $t('labels.status') }}</label>
            <v-select
              v-model="searchFields.status"
              :dir="$store.state.appConfig.layout.direction"
              :options="statuses"
              :reduce="item => item.id"
              label="name"
              class="w-100"
            />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <TableIndex
      :resource-name="resourceName"
      :singular-name="singularName"
      :filter-fields="searchFields"
      :can-change-status="false"
      :permissionKey='"roles"'
    >
      <template v-slot:custom_actions="{ item, generalActions }">
        <b-link
          v-b-tooltip.hover.v-primary
          :title="$t('Details')"
          class="ml-1"
          :to="{ name: 'roles-details', params: { resourceId: item.id } }"
        >
          <feather-icon
            icon="EyeIcon"
            size="16"
            class="align-middle text-body"
          />
        </b-link>
      </template>
    </TableIndex>
  </div>
</template>

<script>
import listMixin from '@/mixins/listMixin'

export default {
  mixins: [listMixin],
  data() {
    return {
      resourceName: 'roles',
      singularName: 'role',
      searchFields: {
        name_value: null,
        status: null,
      },
      roles: [],
    }
  },
  methods: {
    inputChange: _.debounce(function (e, field) {
      this.searchFields[field] = e
    }, 500),
  },
  // eslint-disable-next-line vue/order-in-components
  computed: {
    statuses() {
      return [
        {
          id: 1,
          name: this.$t('statuses.active'),
        },
        {
          id: 0,
          name: this.$t('statuses.inactive'),
        },
      ]
    },
  },
}
</script>
